import cn from 'classnames';
import { Grid, ColsType } from './interface';
import { getClassName } from './utils';

export interface RowProps extends Grid {
  size?: ColsType;
  customRef?: React.Ref<HTMLDivElement> | null;
}

const Row: React.FC<RowProps> = ({
  size,
  offset,
  g,
  gx,
  gy,
  className,
  style,
  children,
  customRef,
  ...props
}) => (
  <div
    className={cn(`row`, className, {
      [`row-cols-${size}`]: size,
      ...getClassName({ offset, g, gx, gy }),
    })}
    style={style}
    ref={customRef}
    {...props}
  >
    {children}
  </div>
);

export default Row;
